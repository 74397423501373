<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      status: {
        shopee: 'inactive',
      }
    };
  },
  methods: {
    getShopee() {
      api.get("integracoes/shopee").then((res) => {
        this.status.shopee = res.data.status;
      });
    },
  },
  mounted() {
    this.getShopee();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Integrações</h3>
        <p>Gerencie ou integre um canal de venda.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <router-link tag="a" to="/integracoes/shopee">
          <div class="card">
            <div class="card-body text-center">
              <img class="integracao-logo" src="@/assets/images/integracoes/shopee.svg" /><br />
              <span v-if="status.shopee == 'inactive'" class="bg-danger rounded px-2 font-size-12 text-white">INATIVO</span>
              <span v-else class="bg-success rounded px-2 font-size-12 text-white">ATIVO</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link tag="a" to="/integracoes">
          <div class="card">
            <div class="card-body text-center">
              <img class="integracao-logo inactive" src="@/assets/images/integracoes/mercadolivre.svg" /><br />
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link tag="a" to="/integracoes">
          <div class="card">
            <div class="card-body text-center">
              <img class="integracao-logo inactive" src="@/assets/images/integracoes/amazon.svg" /><br />
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link tag="a" to="/integracoes">
          <div class="card">
            <div class="card-body text-center">
              <img class="integracao-logo inactive" src="@/assets/images/integracoes/shopify.svg" /><br />
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link tag="a" to="/integracoes">
          <div class="card">
            <div class="card-body text-center">
              <img class="integracao-logo inactive" src="@/assets/images/integracoes/tray.svg" /><br />
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link tag="a" to="/integracoes">
          <div class="card">
            <div class="card-body text-center">
              <img class="integracao-logo inactive" src="@/assets/images/integracoes/lojaintegrada.svg" /><br />
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.integracao-logo {
  margin-bottom: 10px;
  max-width: 250px;
}

.integracao-logo.inactive {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
  opacity: 0.4;
}
</style>
